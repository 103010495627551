import './beekeeper-form.scss';

import { postAjax } from '../helpers/helpers';
import { BeekeeperMap } from '../map/map';
import { ProductsGrid } from '../products-grid/products-grid';

export class BeekeeperForm {
    constructor(target) {
        this.classes = {
            formClass: 'beekeeper-form',
            inputClass: 'beekeeper-form__input',
            submitClass: 'beekeeper-form__submit',
            resultsClass: 'beekeeper-form__results',
            loaderClass: 'beekeeper-form__loader',
        };

        this.element       = $(target);
        this.input         = $('.' + this.classes.inputClass);
        this.form          = $('.' + this.classes.formClass);
        this.results       = $('.' + this.classes.resultsClass);
        this.loader        = $('.' + this.classes.loaderClass);
        // this.submit        = $('.' + this.classes.submitClass);
        this.loaderMarkup  = '<span class="beekeeper-form__loader"></span>';
        this.liveTimer     = 0;
        this.isLoading     = false;

        this.init();
    }

    init() {
        this.form.on('submit', this.searchHandler.bind(this));
    }

    searchHandler(event) {
        event.preventDefault();

        const val = this.input.val();
        clearTimeout(this.liveTimer);

        if (val.length > 2) {
            if(!this.isLoading) {
                this.isLoading = true;
                this.results.empty().removeClass('loaded');
                this.results.append(this.loaderMarkup);
            }

            postAjax(window.estpress.ajaxPath + '?action=load-beekeeper', {'date': val}, (response) => {
                this.loader.remove();
                this.results.empty().addClass('loaded');
                this.isLoading = false;
                this.isResults = true;
                this.results.html(JSON.parse(response).data);
                if(JSON.parse(response).success == true) {
                    new BeekeeperMap($('.js-map')[0]);
                    new ProductsGrid($('.js-products-grid'));
                }
            });

            // this.liveTimer = setTimeout(() => {
            //     postAjax(window.estpress.ajaxPath + '?action=load-beekeeper', {'date': val}, (response) => {
            //         this.loader.remove();
            //         this.results.empty().addClass('loaded');
            //         this.isLoading = false;
            //         this.isResults = true;
            //         this.results.html(JSON.parse(response).data);
            //         if(JSON.parse(response).success == true) {
            //             new BeekeeperMap($('.js-map'));
            //         }
            //     });

            // }, 1000);
        } else {
            this.results.empty().removeClass('loaded');
            this.isLoading = false;
        }
    }
}

// Init on document ready
$(function () {
    $('.js-beekeeper-form').map(function() {
        new BeekeeperForm(this);
    });
});
